<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
  props: ["idjadwal", "id_kompetensi", "idjadwal_peserta", "level"],
  data() {
    return {
      formData: {
        idjadwal: this.$props.idjadwal,
        idsoal: "",
        time_remaining: "",
        kompetensi_idkompetensi: this.$props.id_kompetensi,
        jadwal_peserta_idjadwal_peserta: this.$props.idjadwal_peserta,
      },
      list_data: [],
      total_soal: "",
      total_terjawab: "",
      is_started: false,
      countdown : "",
    };
  },
  mounted() {
    this.getDataRedis();
  },
  methods: {
    getDataRedis() {
      let url =
        process.env.VUE_APP_BACKEND_URL_VERSION +
        "master/jadwal-pelaksanaan/get-list-redis";

      var config = {
        method: "get",
        url: url,
        params: {
          idjadwal: this.idjadwal,
          id_kompetensi: this.id_kompetensi,
          idjadwal_peserta: this.idjadwal_peserta,
          level: this.level,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data;
          if (response_data.meta.code == 200) {
            this.list_data = response_data_fix;
            var data_soal = response_data_fix.filter(
              (item) => item.is_instruksi !== 1
            );

            let data_soal_terjawab = data_soal.filter(
              (item) => item.jawaban_peserta !== null
            );

            this.total_soal = data_soal.length;
            this.total_terjawab = data_soal_terjawab.length;

            let last_index = this.list_data.length;
            var countdown;

            this.list_data.forEach((element, index) => {
              if (element.is_instruksi == 0) {
                if (element.jawaban_peserta != null) {
                  if (this.list_data[index + 1] != last_index - 1) {
                    if (this.list_data[index + 1].jawaban_perserta == null) {
                      countdown = element.jawaban_peserta.time_remaining;
                      this.formData.time_remaining = countdown;
                      this.is_started = true;
                      this.formData.idsoal = element.idsoal;
                    }
                  }
                }
              }
            });
          
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    submitForm() {
      const authToken = localStorage.access_token;
      axios
        .post(
          process.env.VUE_APP_BACKEND_URL_VERSION + "master/jadwal-pelaksanaan/update-timer-redis",
          this.formData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          console.log("Response:", response.data);
          this.resetForm();
          window.location.reload();
        })
        .catch((error) => {
          // Handle errors
          console.error("Error:", error.response.data);
        });
    },
    resetForm() {
      this.formData.keterangan = "";
      this.formData.tanggal_mulai = "";
      this.formData.tanggal_selesai = "";
    },
  },
};
</script>

<style scoped>
/* Add your custom styles here if needed */
</style>

<template>
  <div class="container">
    <div class="row">
      <h5>Progress Peserta Asesmen</h5>
    </div>
    <div class="row mt-2" v-if="total_terjawab && total_terjawab">
      <b-progress :max="total_soal" height="2rem">
        <b-progress-bar :value="total_terjawab" show-progress animated>
          <span
            >Progress:
            <strong>{{ total_terjawab }} / {{ total_soal }}</strong></span
          >
        </b-progress-bar>
      </b-progress>
    </div>
    <div class="row mt-4">
      <h5>Ubah Timer <span class="text-danger h6">(Pastikan user telah logout sebelum diubah timer)</span></h5>
    </div>
    <div class="row">
      <div class="col-md-6 mb-3">
        <input
          v-model="formData.time_remaining"
          type="number"
          class="form-control"
          id="tanggal_selesai"
          required
          :disabled="!is_started"
        />
      </div>

      <div class="col-md-6 mb-3">
        <button type="submit" class="btn btn-primary" :disabled="!is_started" @click="submitForm()">Submit
        </button>
      </div>
    </div>
  </div>
</template>
