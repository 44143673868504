<script>
import axios from "axios";
import Datatable from "@/router/layouts/table-datatable.vue";
import Pagination from "@/router/layouts/table-pagination.vue";
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import ModalProgress from "./modal-progress.vue";
export default {
  page: {
    title: "Penjadwalan",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Datatable,
    Pagination,
    Layout,
    ModalProgress,
    PageHeader,
  },

  watch: {
    idJadwal: function (newVal, oldVal) {
      console.log("newVal", newVal);
      console.log("oldVal", oldVal);
      if (newVal != oldVal) {
        this.getPeserta();
      }
    },
  },
  data() {
    return {
      idJadwal: this.$route.params.idjadwal,
      column: [
        {
          width: "150px",
          label: "Batch",
          name: "-",
        },
        {
          width: "auto",
          label: "NIP",
          name: "-",
        },
        {
          width: "auto",
          label: "Nama",
          name: "-",
        },
        {
          width: "50%",
          label: "Kompetensi",
          name: "-",
        },
        {
          width: "200px",
          label: "Status",
          name: "-",
        },
        // {
        //   width: "auto",
        //   label: "Aksi",
        //   name: "-",
        // },
      ],
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },
      tableFilter: {
        page: 1,
        per_page: 10,
        nip: "",
        nama: "",
        nama_batch: "",
        kompetensi: "",
        status: "",
      },
      tableData: [],
      optionFilterBatch: [],
      optionFilterKompetensi: [],
      optionFilterStatus: [
        {
          value: 1,
          label: "Belum Mengerjakan",
        },
        {
          value: 2,
          label: "Sedang Mengerjakan",
        },
        {
          value: 3,
          label: "Sudah Mengerjakan",
        },
      ],

      showModalProgress: false,
      selected_idjadwal: "",
      selected_idkompetensi: "",
      selected_idlevel: "",
      selected_idpeserta: "",
    };
  },
  mounted() {
    this.getPeserta();
    this.getFilterBatch();
    this.getFilterKompetensi();
  },
  methods: {
    async getFilterBatch(search = "", loading) {
      await axios({
        method: "get",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "referensi/jadwal-batch/all",
        params: {
          search: search,
          limit: 10,
        },
        headers: {
          Authorization: "Bearer " + localStorage.access_token,
        },
      }).then((response) => {
        this.optionFilterBatch = response.data.data;
        if (loading) loading(false);
      });
    },
    async searchBatch(search, loading) {
      if (search.length) {
        loading(true);
        this.getFilterBatch(search, loading);
      }
    },
    async getFilterKompetensi(search = "", loading) {
      await axios({
        method: "get",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/kompetensi-jadwal/all",
        params: {
          search: search,
          limit: 10,
          id_jadwal: this.idJadwal,
        },
        headers: {
          Authorization: "Bearer " + localStorage.access_token,
        },
      }).then((response) => {
        this.optionFilterKompetensi = response.data.data;
        if (loading) loading(false);
      });
    },
    async searchKomptensi(search, loading) {
      if (search.length) {
        loading(true);
        this.getFilterKompetensi(search, loading);
      }
    },
    async getPeserta() {
      const data = await axios({
        method: "get",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "master/jadwal-pelaksanaan/" +
          this.idJadwal +
          "/monitor",
        params: this.tableFilter,
        headers: {
          Authorization: "Bearer " + localStorage.access_token,
        },
      })
        .then((response) => response.data.data)
        .catch((err) => {
          console.log(err);
          return false;
        });
      if (data) {
        this.tableData = data.data;
        this.pagination.lastPage = data.last_page;
        this.pagination.currentPage = data.current_page;
        this.pagination.total = data.total;
        this.pagination.lastPageUrl = data.last_page_url;
        this.pagination.nextPageUrl = data.next_page_url;
        this.pagination.prevPageUrl = data.prev_page_url;
        this.pagination.from = data.from;
        this.pagination.to = data.to;
        this.pagination.links = data.links;
      }
    },
    toPage(url) {
      let link = new URLSearchParams(url);
      this.tableFilter.page = link.get("page");
      // this.tableFilter.per_page = link.get('per_page');
      this.getPeserta();
    },
    listKompetensi(row) {
      if (
        row.kompetensi_with_status &&
        row.kompetensi_with_status.includes(",")
      ) {
        return row.kompetensi_with_status.split(",");
      }
      let listKomp = [];
      listKomp.push(row.kompetensi_with_status);
      console.log(listKomp, row.kompetensi_with_status);
      return listKomp;
    },
    getKompetensi(val, index = 0) {
      let kompetensi = val.split("|");
      return kompetensi[index];
    },
    statusMonitor(row) {
      let status = "Belum Mengerjakan";
      if (
        row.kompetensi_with_status &&
        row.kompetensi_with_status.includes(",")
      ) {
        let listKompetensi = this.listKompetensi(row);
        if (listKompetensi) {
          listKompetensi.forEach((e) => {
            let currentStatus = e.split(" - ")[1];
            if (currentStatus == "Sedang Mengerjakan") {
              status = currentStatus;
            } else if (
              currentStatus == "Sudah Mengerjakan" &&
              status != "Sedang Mengerjakan"
            ) {
              status = currentStatus;
            }
          });
        }
      }
      return status;
    },
    setShowModalProgress(idjadwal, idjadwal_peserta, id_kompetensi, level) {
      this.showModalProgress = !this.showModalProgress;
      this.selected_idjadwal = idjadwal;
      this.selected_idkompetensi = id_kompetensi;
      this.selected_idlevel = level;
      this.selected_idpeserta = idjadwal_peserta;
    },
    exportExcel() {
      let params = new URLSearchParams(this.tableFilter).toString();
      window.open(
        process.env.VUE_APP_BACKEND_URL_VERSION +
          "export-excel/jadwal-pelaksanaan/"+this.idJadwal +
          "/monitor?" +
          params
      );
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row mb-2">
      <div class="col-12">
        <router-link
          class="btn btn-danger btn-sm"
          :to="{ name: 'all-jadwal-pelaksanaan' }"
          >Kembali</router-link
        >
      </div>
    </div>
    <div class="row">
      <div class="card">
        <div class="card-body">
          <div class="row">
          <div class="col-6">
            <div class="card-title">Monitoring Jadwal Pelaksanaan</div>
          </div>
          <div class="col-6 text-end">
            <button class="btn btn-success btn-sm" @click="exportExcel()">
                <i class="fa fa-file-excel me-1"></i> Export Excel
              </button>
          </div>
          </div>
          <div class="row mt-4">
            <Datatable :columns="column">
              <tbody>
                <tr>
                  <td>
                    <v-select
                      id="filter_batch"
                      class="style-chooser"
                      :options="optionFilterBatch"
                      :reduce="(batch) => batch.nama_batch"
                      :deselectFromDropdown="true"
                      label="nama_batch"
                      v-model="tableFilter.nama_batch"
                      placeholder="Filter Batch"
                      @search="searchBatch"
                      @option:selected="getPeserta"
                      @option:deselected="getPeserta"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Filter NIP"
                      v-model="tableFilter.nip"
                      @change="getPeserta()"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Filter Nama"
                      v-model="tableFilter.nama"
                      @input="getPeserta()"
                    />
                  </td>
                  <td>
                    <v-select
                      id="filter_kompetensi"
                      class="style-chooser"
                      :options="optionFilterKompetensi"
                      :reduce="(kompetensi) => kompetensi.nama_kompetensi"
                      :deselectFromDropdown="true"
                      label="nama_kompetensi"
                      v-model="tableFilter.kompetensi"
                      placeholder="Filter Kompetensi"
                      @search="searchKomptensi"
                      @option:selected="getPeserta"
                      @option:deselected="getPeserta"
                    />
                  </td>
                  <td>
                    <v-select
                      id="filter_status"
                      class="style-chooser"
                      :options="optionFilterStatus"
                      :reduce="(status) => status.value"
                      :deselectFromDropdown="true"
                      label="label"
                      v-model="tableFilter.status"
                      placeholder="Filter Status"
                      @option:selected="getPeserta"
                      @option:deselected="getPeserta"
                    />
                  </td>
                </tr>
                <tr
                  v-for="row in tableData"
                  :key="row.jadwal_peserta_idjadwal_peserta"
                >
                  <td>{{ row.nama_batch }}</td>
                  <td>{{ row.nip }}</td>
                  <td>{{ row.nama }}</td>
                  <td style="padding: 0">
                    <table class="table table-sm m-0">
                      <tr
                        v-for="(komp, index) in listKompetensi(row)"
                        :key="index"
                      >
                        <td>{{ getKompetensi(komp) }}</td>
                        <td class="text-center" style="width: 125px">
                          <button
                            class="btn btn-sm btn-outline-success"
                            v-on:click="
                              setShowModalProgress(
                                idJadwal,
                                row.jadwal_peserta_idjadwal_peserta,
                                getKompetensi(komp, 1),
                                row.level_idlevel
                              )
                            "
                          >
                            <i class="fa fa-eye"></i> Lihat Progress
                          </button>
                        </td>
                      </tr>
                    </table>
                    <!-- <ul>
                      <div
                        v-for="(komp, index) in listKompetensi(row)"
                        :key="index"
                        class="d-flex justify-content-between my-1"
                      >
                        <li>{{ komp }}</li> 
                      </div>
                    </ul> -->
                  </td>
                  <td>{{ statusMonitor(row) }}</td>
                  <!-- <td>
                    <button
                      v-on:click="setShowModalProgress(idJadwal,row.jadwal_peserta_idjadwal_peserta,row.kompetensi_idkompetensi,row.level_idlevel)"
                      class="btn btn-sm btn-success"
                    >
                      <i class="fa fa-eye"></i> Lihat Progress
                    </button>
                  </td> -->
                </tr>
              </tbody>
            </Datatable>
            <pagination :pagination="pagination" @to-page="toPage"></pagination>
          </div>
        </div>
      </div>
    </div>
  </Layout>
  <b-modal
    v-model="showModalProgress"
    hide-footer
    title="Progress Asesmen"
    size="xl"
    scrollable
  >
    <ModalProgress
      v-if="showModalProgress"
      :idjadwal="selected_idjadwal"
      :id_kompetensi="selected_idkompetensi"
      :idjadwal_peserta="selected_idpeserta"
      :level="selected_idlevel"
    />
  </b-modal>
</template>

<style scoped></style>
